/* ----------------------------------
	About Us Section Styles
 ------------------------------------ */
.about-us-section {
  .about-shots {
    position: relative;

    .about-top-img {
      width: 320px;
      height: 280px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-115px);
      border-style: solid;
      border-width: 10px;
      border-color: rgb(255, 255, 255);
      background-color: #fff;
      box-shadow: 0px 16px 32px 0px rgba(32, 36, 38, 0.1);

      @media (min-width: 991px) and (max-width: 1199px) {
        right: -30px;
      }

      @media (max-width: 767px) {
        transform: translateX(50px) translateY(-115px);
      }
      @media (max-width: 500px) {
        width: 240px;
        height: 200px;
        transform: translateY(-145px) translateX(-20px);
        background-color: #fff;
      }
    }

    .our-experience {
      width: 261px;
      height: 216px;
      position: absolute;
      bottom: 100px;
      right: -100px;
      background-color: rgb(0, 186, 163);
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.6;

      @media (min-width: 991px) and (max-width: 1199px) {
        bottom: -30px;
      }

      @media (max-width: 991px) {
        right: 20px;
      }

      @media (max-width: 767px) {
        right: -50px;
        bottom: -30px;
      }

      h1 {
        margin-top: 50px;
        font-size: 80px;
        line-height: 1;
      }
    }
  }

  .list-box {
    margin-top: 30px;

    li {
      width: 50%;
      float: left;
      overflow: hidden;
      position: relative;
      padding-left: 15px;
      font-weight: 700;
      margin-top: 10px;
      display: block;

      @media (max-width: 585px) {
        width: 100%;
        float: none;
      }

      &::before {
        position: absolute;
        left: 0;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        width: 7px;
        height: 2px;
        background: #00baa3;
      }

      &:nth-child(2) {
        &::before {
          background-color: #d55342;
        }
      }

      &:nth-child(3n) {
        &::before {
          background-color: #efc94c;
        }
      }

      &:nth-child(4n) {
        &::before {
          background-color: #9238a4;
        }
      }
    }
  }

  .testimonial-quote {
    margin-top: 35px;
    box-shadow: 0px 16px 32px 0px rgba(129, 129, 129, 0.06);
    background-color: #fff;
    padding: 40px;
    position: relative;
    z-index: 1;

    @media (max-width: 585px) {
      overflow: hidden;
      padding: 30px;
    }

    &::before {
      position: absolute;
      right: -25px;
      content: "";
      bottom: -20px;
      width: 141px;
      height: 98px;
      // background: url("../img/99.png") no-repeat;
      z-index: -1;
    }

    .feedback {
      color: #727475;
      a {
        color: #d55342;
        text-decoration: underline;
        font-weight: 700;

        @media (max-width: 585px) {
          font-size: 14px;
        }
      }
    }

    .user-info {
      margin-top: 15px;

      .profile-img {
        background-color: rgb(233, 233, 233);
        width: 40px;
        height: 40px;
        float: left;
        overflow: hidden;
        margin-right: 18px;
      }

      span {
        color: #202426;
        font-size: 20px;
        line-height: 1;
        font-weight: 700;
        letter-spacing: -0.3px;

        @media (max-width: 585px) {
          font-size: 16px;
        }
      }
    }
  }
}

.checked-list {
  @media (max-width: 991px) {
    margin-left: 30px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }

  li {
    font-size: 16px;
    color: #727475;
    font-weight: 700;
    margin-top: 10px;
    padding-left: 40px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "\f00c";
      font-family: $fa;
      font-weight: 400;
      color: rgb(0, 186, 163);
    }
  }
}

.about_left_content {
  margin-top: -60px;

  @media (max-width: 991px) {
    margin-top: 0;
    padding-top: 40px;

    a {
      margin-left: 30px;
    }
  }

  p {
    margin-top: 30px;
  }

  .section-title {
    span {
      color: #d55342;
    }
    h1 {
      span {
        color: rgb(0, 186, 163);
      }
    }
  }
}

.about-section {
  .org-logo-wrap {
    position: relative;
    margin-bottom: 50px;
    z-index: 1;

    &::before {
      border-style: solid;
      border-width: 2px;
      border-color: rgb(250, 250, 250);
      border-radius: 50%;
      width: 348px;
      height: 348px;
      position: absolute;
      left: 50%;
      top: 50%;
      content: "";
      z-index: -1;
      transform: translate(-50%, -50%);
    }
    &::after {
      border-style: solid;
      border-width: 2px;
      border-color: rgb(250, 250, 250);
      border-radius: 50%;
      width: 261px;
      height: 261px;
      position: absolute;
      left: 50%;
      top: 50%;
      content: "";
      z-index: -1;
      transform: translate(-50%, -50%);
    }

    .org-logo {
      padding: 42px 50px;
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 16px 32px 0px rgba(183, 186, 187, 0.1);
    }
  }

  .single-about-img {
    height: 400px;
    width: 100%;
    margin-top: 20px;
  }

  .section-title {
    > span {
      color: #d55342;
    }

    h1 {
      span {
        color: #00baa3;
      }

      @media (max-width: 585px) {
        font-size: 26px;
        line-height: 1.4;
      }
    }
  }
}

.timeline-wrap {
  overflow: hidden;

  .featured-timeline {
    .timeline__content {
      h2,
      p {
        display: inline-block;
        margin-bottom: 0;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      p {
        padding-left: 5px;
      }

      border: none;
      border-radius: 0px;
      padding: 0px;
      text-align: center;

      img {
        width: 100%;
      }
    }
  }
}

.skills-section {
  .section-title {
    span {
      color: #d55342;
    }
  }

  .single-skill {
    margin-top: 50px;

    p {
      font-size: 18px;
    }

    &:nth-child(2) {
      .skillprogress {
        span {
          color: #00baa3;
        }
      }
    }

    .skillprogress {
      position: relative;
      display: inline-block;
      width: 180px;
      height: 180px;
      text-align: center;
      margin-bottom: 20px;

      canvas {
        position: absolute;
        top: 0;
        left: 0;
      }

      .percent {
        display: block;
        line-height: 180px;
        z-index: 2;

        &::after {
          content: "%";
          margin-left: 0.1em;
          font-size: 0.6em;
        }
      }

      span {
        font-weight: 700;
        font-size: 60px;
        color: #d55342;
        font-family: $heading-font;
      }
    }
  }

  .skill-box-items {
    .col-12 {
      &:nth-child(2) {
        margin-top: 30px;
      }
      &:nth-child(4) {
        margin-top: 30px;
      }

      @media (max-width: 576px) {
        &:nth-child(3) {
          margin-top: 30px;
        }
      }
    }

    .single-skill-box {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;

      &:hover {
        &:before {
          opacity: 0.9;
          visibility: visible;
        }
        .skill-content {
          margin-top: 0;
          opacity: 1;
          visibility: visible;
        }
      }

      &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgb(0, 186, 163);
        opacity: 0;
        visibility: hidden;
        left: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
      }

      .skill-content {
        position: relative;
        z-index: 9;
        margin-top: 40px;
        transition: all 0.4s ease-in-out;
        opacity: 0;
        visibility: hidden;

        .icon {
          border-radius: 50%;
          width: 100px;
          height: 100px;
          font-size: 40px;
          line-height: 100px;
          display: inline-block;
          margin: 0 auto;
          background-color: #fff;
          color: #00baa3;
          margin-bottom: 20px;
        }

        h3 {
          margin-bottom: 8px;
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
    }
  }
}

.brands-carousel-section {
  &.bg-overlay {
    &:before {
      background: #efc94c;
      opacity: 0.9;
    }
  }
}

.about-wrapper {
  .block-contents {
    h1 {
      color: $second-color;
      font-size: 56px;

      @media (max-width: 1199px) {
        font-size: 40px;
        line-height: 130%;
      }

      @media (max-width: 767px) {
        font-size: 32px;
      }
    }

    h4 {
      border-color: $theme-color-2;
      font-weight: 400;

      @media (max-width: 1199px) {
        padding: 5px 15px;
        font-size: 18px;
        margin: 20px 0px;
      }
    }

    span {
      display: block;
      color: $theme-color-2;

      b {
        color: $second-color;
      }
    }
  }
}

.about-promo {
  display: inline-block;
  width: 520px;
  height: 614px;
  background: $theme-color;

  @media (max-width: 991px) {
    height: 500px;
  }

  @media (max-width: 600px) {
    width: 80%;
    height: 400px;
  }

  .skill-popup-video {
    border-width: 10px;
    border-color: white;
    border-style: solid;
    border-radius: 50%;
    background-color: #bfbfbf;
    box-shadow: 0px 10px 60px 0px $theme-color;
    width: 190px;
    height: 190px;
    z-index: 2;
    position: absolute;
    bottom: 60px;
    right: -85px;

    @media (max-width: 585px) {
      transform: translateX(-15%);
      -webkit-transform: translateX(-15%);
      -moz-transform: translateX(-15%);
      -ms-transform: translateX(-15%);
      -o-transform: translateX(-15%);
    }
  }

  .video-play-btn {
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: $theme-color;
    color: #fff;
    border-radius: 50%;
    line-height: 54px;

    a {
      &:hover {
        color: #fff;
      }
    }
  }
}

.brand-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 60px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  .single-brand-logo {
    border: 1px solid #eee;
    padding: 50px;
    text-align: center;
  }
}

.promo-featured-wrapper {
  @media (max-width: 1199px) {
    img {
      width: 70%;
    }
  }
}
